<template>
  <div class="step-four d-flex flex-column pt-98">
    <div v-if="failed">
      <div class="text-center mt-40 mb-20">
        <img :src="certification" alt="" />
      </div>
      <div class="text-center font-16">认证已提交</div>
      <div class="text-center font-14 text_muted">请耐心等待审核结果</div>
      <div class="text-center">
        <Button @click="handleJump" class="mt-42" size="normal">查看列表</Button>
      </div>
    </div>
    <div v-else>
      <div class="text-center mt-40 mb-20">
        <img :src="failedImage" alt="" />
      </div>
      <div class="text-center font-16 mb-20 text_danger">认证失败</div>
      <div class="text-center text_muted" v-for="(item, index) in refuseReason" :key="index">
        {{ $$(item, 'remarks') }}
      </div>
      <div class="text-center">
        <Button class="mt-42" @click="handleReset" type="primary">重新认证</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, nextTick, ref } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';
import { useTrailerVerifiedStore } from '@/store/trailer.js';
import { Button } from 'vant';
import { useUserStore } from '@/store/user';

export default defineComponent({
  name: 'truck-step4',
  components: {
    Button
  },
  setup() {
    const isDriver = computed(() => useUserStore().isDriver);
    const userVerifiedStore = useTrailerVerifiedStore();
    const refuseReason = computed(() => userVerifiedStore.verifiedInfo.refuseReason);
    const failed = ref(true);

    const certification = require('@/assets/tj.png');
    const failedImage = require('@/assets/tjsb.png');
    const router = useRouter();

    const handleJump = async () => {
      await userVerifiedStore.clearInfo();
      nextTick(() => {
        router.push(PageEnum.BASE_CAR_LIST, { query: { type: 'trailer' } });
      });
    };
    const handleReset = async () => {
      // 重新认证
      // await userVerifiedStore.resetAuditTruck();
      userVerifiedStore.updateInfo({
        step: 0
      });
    };
    return {
      certification,
      handleJump,
      failedImage,
      handleReset,
      refuseReason,
      failed
    };
  }
});
</script>
<style lang="less">
.step-four {
  img {
    width: 0.84rem;
  }
}
</style>
